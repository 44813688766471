import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Carousel from 'react-bootstrap/Carousel';

// Todo: describe each image in the 'alt' attribute for accessibility purposes.
const Slideshow = () => (
	<Carousel>
		{/* Adding each slide individually instead of a loop because StaticImage does not support dynamic src. */}
		<Carousel.Item>
			<StaticImage
				src='../images/slideshow/img1.jpg'
				alt='Slideshow Image'
				layout='fullWidth'
				placeholder='blurred'
				formats={['auto', 'webp']}
				aspectRatio={1.3182515337}
				class='br'
			/>
		</Carousel.Item>
		<Carousel.Item>
			<StaticImage
				src='../images/slideshow/img2.jpg'
				alt='Slideshow Image'
				layout='fullWidth'
				placeholder='blurred'
				formats={['auto', 'webp']}
				aspectRatio={1.3182515337}
				class='br'
			/>
		</Carousel.Item>
		<Carousel.Item>
			<StaticImage
				src='../images/slideshow/img3.jpg'
				alt='Slideshow Image'
				layout='fullWidth'
				placeholder='blurred'
				formats={['auto', 'webp']}
				aspectRatio={1.3182515337}
				class='br'
			/>
		</Carousel.Item>
		<Carousel.Item>
			<StaticImage
				src='../images/slideshow/img4.jpg'
				alt='Slideshow Image'
				layout='fullWidth'
				placeholder='blurred'
				formats={['auto', 'webp']}
				aspectRatio={1.3182515337}
				class='br'
			/>
		</Carousel.Item>
		<Carousel.Item>
			<StaticImage
				src='../images/slideshow/img5.jpg'
				alt='Slideshow Image'
				layout='fullWidth'
				placeholder='blurred'
				formats={['auto', 'webp']}
				aspectRatio={1.3182515337}
				class='br'
			/>
		</Carousel.Item>
		<Carousel.Item>
			<StaticImage
				src='../images/slideshow/img6.jpg'
				alt='Slideshow Image'
				layout='fullWidth'
				placeholder='blurred'
				formats={['auto', 'webp']}
				aspectRatio={1.3182515337}
				class='br'
			/>
		</Carousel.Item>
		<Carousel.Item>
			<StaticImage
				src='../images/slideshow/img7.jpg'
				alt='Slideshow Image'
				layout='fullWidth'
				placeholder='blurred'
				formats={['auto', 'webp']}
				aspectRatio={1.3182515337}
				class='br'
			/>
		</Carousel.Item>
		<Carousel.Item>
			<StaticImage
				src='../images/slideshow/img8.jpg'
				alt='Slideshow Image'
				layout='fullWidth'
				placeholder='blurred'
				formats={['auto', 'webp']}
				aspectRatio={1.3182515337}
				class='br'
			/>
		</Carousel.Item>
		<Carousel.Item>
			<StaticImage
				src='../images/slideshow/img9.jpg'
				alt='Slideshow Image'
				layout='fullWidth'
				placeholder='blurred'
				formats={['auto', 'webp']}
				aspectRatio={1.3182515337}
				class='br'
			/>
		</Carousel.Item>

		<Carousel.Item>
			<StaticImage
				src='../images/slideshow/img12.jpg'
				alt='Slideshow Image'
				layout='fullWidth'
				placeholder='blurred'
				formats={['auto', 'webp']}
				aspectRatio={1.3182515337}
				class='br'
			/>
		</Carousel.Item>

		<Carousel.Item>
			<StaticImage
				src='../images/slideshow/img15.png'
				alt='Slideshow Image'
				layout='fullWidth'
				placeholder='blurred'
				formats={['auto', 'webp']}
				aspectRatio={1.3182515337}
				class='br'
			/>
		</Carousel.Item>
	</Carousel>
);

export default Slideshow;
